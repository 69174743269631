<template lang="pug">
  v-app
    .row.flex-column(:class="mobileView ? '' : 'mt-8'")
      .top-image(:class="mobileView ? 'flex-column' : 'flex-row'")
        .top-container(:class="mobileView ? 'flex-row' : 'flex-column'")
          .image
            img(src="@/assets/images/new/num1.png")
          .label(v-html="$t('competition.line1')")
        .top-container(:class="mobileView ? 'flex-row' : 'flex-column'") 
          .image
            img.line(:src="mobileView ? require('@/assets/images/new/num-line-r.png') : require('@/assets/images/new/num-line.png')")
          .label
        .top-container(:class="mobileView ? 'flex-row' : 'flex-column'")   
          .image
            img(src="@/assets/images/new/num2.png")
          .label(v-html="$t('competition.line2')")
        .top-container(:class="mobileView ? 'flex-row' : 'flex-column'") 
          .image
            img.line(:src="mobileView ? require('@/assets/images/new/num-line-r.png') : require('@/assets/images/new/num-line.png')")
          .label
        .top-container(:class="mobileView ? 'flex-row' : 'flex-column'") 
          .image
            img(src="@/assets/images/new/num3.png")
          .label(v-html="$t('competition.line3')")
        .top-container(:class="mobileView ? 'flex-row' : 'flex-column'") 
          .image
            img.line(:src="mobileView ? require('@/assets/images/new/num-line-r.png') : require('@/assets/images/new/num-line.png')")
          .label
        .top-container(:class="mobileView ? 'flex-row' : 'flex-column'") 
          .image
            img(src="@/assets/images/new/num4.png")
          .label(v-html="$t('competition.line4')")
      v-btn(
        color="primary"
        dense
        v-bind="size"
        @click="openVideo"
      )
        b {{ $t("competition.join") }}
    .row
      .box-full(v-if="!mobileView")
        img(:src="require(`@/assets/images/competition/competition2_${$i18n.locale}.png`)" class="img-fluid")
      .box(v-if="mobileView")
        img(:src="require(`@/assets/images/competition/competition3_${$i18n.locale}.png`)" class="img-fluid")
    .row(style="margin-top: -3rem")
      .box-full
        img(:src="require(`@/assets/images/competition/competition4_${$i18n.locale}.png`)" class="img-fluid")
    .row(style="margin-top: 3rem")
      .box-full
        img(:src="require(`@/assets/images/competition/competition5_${$i18n.locale}.png`)" class="img-fluid")
    v-dialog(
      v-model="dialogVideo"
      max-width="1000px"
    )
      v-card.py-4
        v-card-text
          v-form(
            ref="video"
            v-model="valid"
            lazy-validation
          )
            h3
              b {{ $t("competition.description") }}
            v-textarea(
              outlined
              v-model="description"
              :rules="[v => !!v || this.$t(`auth.required`)]"
            )
            h3
              b {{ $t("competition.url") }}
            v-text-field(
              outlined
              v-model.trim="videoUrl"
              :rules="[v => !!v || this.$t(`auth.required`)]"
            )
            h3
              b {{ $t("competition.phone") }}
            v-text-field(
              outlined
              v-model.trim="phone"
              :rules="[v => !!v || this.$t(`auth.required`)]"
            )
        v-card-actions
          v-btn(
            v-bind="size"
            color="primary"
            @click="saveVideo"
          )
            b {{ $t("competition.submit") }}
    v-dialog(
      v-model="dialogComplete"
      max-width="1000px"
    )
      v-card.py-4
        v-card-title
          h3
            b {{ $t("competition.accepted") }}
        v-card-text.d-flex
          img(class="m-auto" src="@/assets/images/competition/video-submitted.png")
        v-card-actions
          v-btn(
            color="primary"
            @click="close"
            v-bind="size"
          )
            b {{ $t("competition.close") }}
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      valid: false,
      dialogVideo: false,
      dialogComplete: false,
      description: "",
      videoUrl: "",
      video: null,
      phone: "",
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  methods: {
    openVideo() {
      if (!this.$store.getters.isLoggedIn)
        this.$swal
          .fire({
            title: this.$t("auth.login_required"),
            text: this.$t("auth.login_or_register"),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#DB258F",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("auth.go"),
            cancelButtonText: this.$t("auth.close")
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({
                name: "Register"
              });
            }
          });
      else this.dialogVideo = true;
    },
    saveVideo() {
      if (!this.$refs.video.validate()) return;

      let url = config.saveVideoUrl();

      var json = {
        remark: this.description,
        userId: this.$store.getters.userId,
        url: this.videoUrl,
        phone: this.phone
      };

      this.loading = true;

      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            if (response) {
              this.dialogVideo = false;

              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.dialogComplete = true;
              } else {
                this.$swal(
                  this.$t("error.save_video"),
                  response.body.error.message,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.save_video"),
              response.body.error.message,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.save_video"),
            response.body.error.message,
            "error"
          );
        });
    },
    close() {
      this.dialogComplete = false;
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

.row
  margin: 0

.top-image
  display: flex
  width: 95%
  margin: auto
  justify-content: center

  .top-container
    display: flex
    margin: auto 0
    width: 12%
    height: 500px

    .image
      height: 50%
      display: flex

      img, .line
        margin: auto

    .label
      text-align: center
      // font-weight: lighter
      color: $secondary-color
      font-size: 16px
      padding: 4rem 0
      margin: auto
      height: 50%
      width: 100%
      font-weight: bolder

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      width: 95%

      height: unset

      .label
        padding: 0
        margin: auto auto auto 1rem
        height: unset
        max-width: 60%
        font-size: 14px

        ul
          margin: 0 !important

      .image
        height: unset
        margin-left: 2rem
        width: 80px
        display: flex
        // margin: 0 auto

      .line
        margin: auto
        height: 80px

.box
  display: block
  margin-left: auto
  margin-right: auto
  width: 60%
  z-index: 0

  img
    width: 100%

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 85%

.v-btn
  margin-top: 3rem !important
  // max-width: 160px !important
  margin: auto

.box-full
  display: block
  margin: 0 !important
  padding: 0 !important
  width: 100%
  z-index: 0

  img
    width: 100%

h3
  color: $primary-color
</style>
